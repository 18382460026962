/* src/App.css */
/* .App { */
  /* font-family: sans-serif; */
/* } */

/* .main-content { */
  /* margin-left: 260px;  */
  /* padding: 20px; */
/* } */



/* App.css */

.App {
  display: flex;
  height: 100vh; /* Full viewport height */
}

.left-sidenav {
  /* width: 250px; Fixed width for the sidenav */
  background-color: #333;
  color: white;
  padding: 20px;
}

.main-content {
  flex: 1; /* This will take the remaining space */
  display: flex;
  flex-direction: column;
}

/* .top-navbar {
  width: 100%;
  background-color: #555;
  color: white;
  padding: 10px;
} */

.content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}
