.notify-search-text{
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 14px;
    outline: none;
    transition: box-shadow 0.3s;
    width:25%;
    background-image: url("../../assets/Walnut/Vector.svg");
    background-position: 5px 8px; 
    background-repeat: no-repeat;
    padding: 10px 80px 10px 30px;
    height: 40px;
    background-size: 17px 17px; 

}

.notify-search-text::placeholder {
  font-weight: bold; 
}

.search-input{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 20px;
    padding-left: 20px;

}

.send-header{
    background-color: #EAEAEA;
    padding: 20px;
}

.category{

    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 14px;
    height: 40px;
    width: 100%;
}

.category-1{
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 14px;
    height: 40px;
    width: 49%;
    margin-top: 10px;

}

.category-2{

    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 14px;
    height: 40px;
    width: 100%;
    margin-top: 20px;
text-transform: capitalize;

}

.category-3{

    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 14px;
    height: 28px;
    width: 98.5%;
    height: 150px;
    margin-top: 20px;
}

.notify-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding-left: 20px;
    margin-top: 15px;
  }

  .message-input-container {
    position: relative;
    display: flex;
    align-items: flex-start; 
  }
  
  .message-textarea {
    padding-right: 50px; 

    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 14px;
    height: 28px;
    width: 98.5%;
    height: 150px;
    margin-top: 20px;
  }
  
  .send-button {
    position: absolute;
    right: 10px; 
    bottom: -25px;
    padding: 8px 12px; 
    cursor: pointer; 
    background-color: black;
    color: white; 
    border: none; 
    border-radius: 4px; 
  }
  .quill-text{
    width: 100%;
    border-radius: 8px;
    margin-top: 20px;
    height: 130px;
  }

  .notifiy-padding-container{

    width: calc(100% - 100px);
    position: absolute;
    left: 80px;
    margin-top: 75px;
  }

  .react-quill{
height: 150px;
margin-top: 20px;
width: 100%;
text-transform: capitalize;

  }

  .email-category{
    padding: 15px;
  }

@media screen and (max-width: 1440px) {
  .category,
  .category-2 {
    width: 100%; 
  }
  .category-1{
    width: 49%;
  }
}

@media screen and (max-width: 1200px) {
  .category,
  .category-2 {
    width: 97%;
    margin-top: 15px; 
  }
}

@media screen and (max-width: 1024px) {
  .category,
  .category-2 {
    width: 100%; 
    margin-top: 15px;
  }
  
}

@media screen and (max-width: 768px) {
  .category,
  .category-2 {
    width: 100%; 
    margin-top: 10px;
  }
}


@media (min-width: 768px) and (max-width: 1023px) {
  .category-1{
    width:49%;
  }

}

@media (min-width: 1024px) and (max-width: 1439px) {
  .category-1{
    width:49%;
  }

}