.schedule-management {
    padding: 45px;
    font-family: Arial, sans-serif;
    background-color: #f4f6f8;
    color: #333;
}

.schedule-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding-left: 20px;
}

.schedule-filters {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 20px;
}

.schedule-search-text {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 14px;
    outline: none;
    transition: box-shadow 0.3s;
    width: 25%;
    background-image: url("../../assets/Walnut/Vector.svg");
    background-position: 6px 8px;
    background-repeat: no-repeat;
    padding: 10px 80px 10px 30px;
    height: 40px;
    background-size: 17px 17px;
}

.schedule-search-text::placeholder {
    font-weight: bold;
}

.schedule-date-text {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 14px;
    outline: none;
    transition: box-shadow 0.3s;
    width: 175px;
}

/* .schedule-filters input:focus,
.schedule-filters select:focus {
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
} */

.schedule-table {
    width: 100%;
    border-collapse: collapse;

}

.schedule-table th,
.schedule-table td {
    padding: 16px 20px;
    text-align: left;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    color: #555;
}

.schedule-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #333;
}

.schedule-table tr:hover {
    background-color: #f1f5ff;
    cursor: pointer;
}

.schedule-table td:last-child {
    text-align: center;
}

.schedule-table td button {
    background-color: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
    transition: color 0.2s;
}

.schedule-table td button:hover {
    color: #0056b3;
}

.schedule-padding-container {
    width: calc(100% - 100px);
    position: absolute;
    left: 80px;
    margin-top: 75px;

}

.schedule-toggle {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.schedule-switch {
    margin-right: 10px;

}

.schedul-tablediv {
    padding-left: 20px;
}

.schedule-loading {
    text-align: center;
    margin-top: 20px;
}

.schedule-error {
    text-align: center;
    margin-top: 20px;
    color: red;
}

.schedule-calender {
    height: 600px;
    margin: 20px;
    max-height: 600px;
    overflow-y: auto;
}

.schedule-event {
    font-size: 12px;
    display: flex;
}

.schedule-eventtitle {
    margin-top: 3px;
}

.schedule-paragraph {
    color: red;
}

/* Ensure month rows expand dynamically */
.rbc-month-row {
    max-height: 150px; /* Adjust height of each row */
    overflow-y: scroll;
  }
  
  /* Expand the container to fit all events */
  .rbc-day-bg {
    position: relative;
  }
  
  .scrollable-month-event {
    max-height: 80px; /* Adjust based on number of events */
    overflow-y: auto;
    padding: 4px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 2px;
    font-size: 12px;
  }
  
  .rbc-day-bg .rbc-events-container {
    max-height: 120px; /* Ensures the entire day cell scrolls */
    overflow-y: auto;
    padding: 4px;
  }

  .rbc-month-row {
    max-height: 200px; /* More space for events */
  }

  .rbc-event-label{
    display: none;
  }
  
  .rbc-day-slot .rbc-event{
    overflow-y: scroll;
  }

  .week-place{
    display: flex;
  }
  

/* exportbutton */

.export-button {
    margin-left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 50%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Apply different color for selected slot */
.selected-event {
    background-color: rgba(0, 123, 255, 0.5) !important;
    /* Light blue */
    border: 2px solid #007bff !important;
    color: white !important;
}



.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 30px;
    border-radius: 8px;
    min-width: 300px;
    text-align: center;
    width: 25% !important;
}

.modal button {
    margin-top: 10px;
    padding: 8px 12px;
    /* background: #007bff; */
    color: #000;
    border: 1px solid #000;
    cursor: pointer;
    border-radius: 4px;
}

/* .modal button:hover {
    background: #0056b3;
} */

.time-section {
    display: flex;
    justify-content: space-around;
    padding: 15px;
    background: antiquewhite;
    border-radius: 10px;
}

.time-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
}

.time-item i {
    color: #007bff;
    /* Blue color */
    font-size: 18px;
}


/* Custom dropdown styling */
.dropdown {
    appearance: none;
    /* Remove default dropdown styling */
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    padding: 10px;
    padding-right: 40px;
    /* Space for the icon */
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23007bff' width='18px' height='18px'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 18px;
}

/* Remove outline when clicking */
.dropdown:focus {
    outline: none;
    border-color: #000;
}

/* Hover effect */
.dropdown:hover {
    border-color: #000;
}