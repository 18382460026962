form {
  width: 100%; 
  max-width: 600px;
  margin: 0 auto; 
  padding: 1rem; 
  box-sizing: border-box; 
}
.loginpage-sec {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.login-image {
  width: 100%;
  height: 100vh;
}

.signup-container {
  /* display: flex; */
  margin-top: 0;
}

.signup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 150px;
  width: 100%;
  height: 100%;
}

.signup-form h1 {
  font-size: 2rem;
  font-weight: bold;
}

.signup-form p {
  font-size: 1rem;
  color: #555;
}

.input-field {
  margin: 20px 0;
  position: relative;
}

.input-field input {
  width: 74.5%;
  padding: 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.show-password-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.options a {
  color: #007bff;
  text-decoration: none;
}

.login-button {
  width: 74.5%;
  padding: 15px;
  background-color: #333;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.or-divider {
  text-align: center;
  margin: 20px 0;
  font-size: 0.9rem;
  color: #aaa;
}

.google-login,
.apple-login {
  width: 100%;
  padding: 15px;
  background-color: white;
  color: black;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-login i,
.apple-login i {
  margin-right: 10px;
}

.signup-footer {
  text-align: center;
  margin-top: 20px;
}

.signup-footer a {
  color: #007bff;
  text-decoration: none;
}

.signup-footer a:hover {
  text-decoration: underline;
}

.signup-1-link {
  margin-bottom: 1.5rem;
  text-align: center;
}
.eye-open {
  position: absolute;
  right: 45px;
  top: 0px;
  z-index: 1;
}

.remember-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid #ccc;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
  top: 1px;
  margin-right: 5px;
  
}

.remember-checkbox:checked {
  background-color: black;
  border-color: black;
}

.remember-checkbox:checked::after {
  content: '';
  position: absolute;
  left: 3px;
  top: 0px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}



/* Media Queries for Responsiveness */

@media (max-width: 1440px) {
  .login-image {
    width: 100%;
    height: 100vh;
  }
  .signup-form {
    padding: 0px 20px;
  }
  .login-button {
    width: 75%;
  }
}

@media (max-width: 1024px) {
  .login-image {
    width: 100%;
    height: 100vh;
  }
  .login-button {
    width: 75%;
  }
  .signup-form {
    padding: 0 15px;
  }
}

@media (max-width: 768px) {
  .signup-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 15px;
    width: 100%;
    height: 100vh;
  }
  .login-image {
  width: 100%;
  height: 100vh;
  }
  .login-button {
    width: 75%;
  }
}
