.store-management {
  padding: 45px;
  font-family: Arial, sans-serif;
  background-color: #f4f6f8;
  color: #333;
}


.store-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 20px;
  margin-top: 15px;
}

.store-filters {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 20px;
}

.store-search-text {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 14px;
  outline: none;
  transition: box-shadow 0.3s;
  width: 25%;
  background-image: url("../../assets/Walnut/Vector.svg");
  background-position: 6px 8px;
  background-repeat: no-repeat;
  padding: 10px 80px 10px 30px;
  height: 40px;
  background-size: 17px 17px;
}


.store-search-text::placeholder {
  font-weight: bold;
}

/* .store-filters input:focus,
.store-filters select:focus {
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
} */

.store-table {
  width: 100%;
  border-collapse: collapse;
}

.store-table th,
.store-table td {
  padding: 16px 20px;
  text-align: left;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  color: #555;
}

.store-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #333;
}

.store-table tr:hover {
  background-color: #f1f5ff;
  cursor: pointer;
}

.store-table td:last-child {
  text-align: center;
}

.store-table td button {
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
  transition: color 0.2s;
}

.store-table td button:hover {
  color: #0056b3;
}


.in-stock {
  color: #28a745;
  background-color: #e9f7f0;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
}

.out-of-stock {
  color: #dc3545;
  background-color: #f8d7da;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
}

.store-padding-container {
  width: calc(100% - 100px);
  position: absolute;
  left: 80px;
  margin-top: 75px;
}

.capitalize-product {
  text-transform: capitalize;
}


/* Edit product */

.dialog-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-image-tag {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
}

.edit-product-section {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 12px;
}

.modal-button {
  display: flex;
  gap: 20px;
  padding-right: 20px;

}

.save-cancel {

  color: white !important;
  background-color: black !important;
  gap: 10px;
  border: "1px solid #ccc";
  height: 40px;
  width: 120px;
}

.modal-input {
  display: flex;
  padding-left: 17px;
  gap: 185px;
  margin-top: 20px;
}

.modal-inputfield {
  margin-top: 15px;
  height: 35px;
  width: 270px;
  border: 2px solid gray;
  border-radius: 7px;
  padding: 7px;
  text-transform: capitalize;
}

.modal-category {
  margin-bottom: 20px;
  display: flex;
  padding-left: 17px;
  gap: 230px;
  margin-top: 20px;

}

.description-field {
  margin-top: 20px;
  height: 35px;
  border: 2px solid gray;
  border-radius: 7px;
  font-size: 18px;
  padding: 10px;
  text-transform: capitalize;
}


.product-heading {
  font-weight: bold;
  color: black;
}

.product-headings {
  font-size: x-large;
  font-weight: 700;
  color: black;
}

.modal-div {
  display: grid;
}

.modal-lable {

  font-weight: bold;
}

.currency-field {
  border: 2px solid gray;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  outline: none;
  height: 35px;
  margin-top: 15px;
  width: 270px;
}

.modal-categorys {
  display: grid;
}

.category-lable {
  font-weight: bold;
}

.category-div {
  padding: 20px;
}

.purchase-header {
  display: flex;
  gap: 70px;
}

.purchase-header .tab {
  cursor: pointer;
  color: #aaa;
  padding-bottom: 5px;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.purchase-header .tab.active-tab {
  color: black;
  border-bottom: 2px solid black;
}

.store-addnew {
  padding: 15px;
}

.store-tablediv {
  padding: 20px;
}

.product-image {
  width: 50px;
}

.product-edit {
  cursor: pointer;
  margin-right: 10px;
}

.product-delete {
  cursor: pointer;
}

.tab {
  font-size: 1.5rem;
}

.error-messages {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}


.input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.MuiTablePagination-displayedRows {

  margin-bottom: 0 !important;
}

.MuiTablePagination-selectLabel {
  margin-bottom: 0 !important;
}

/* Responsive design for the entire modal */
@media (max-width: 970px) and (min-width: 763px) {

  .modal-input {
    display: block;
    margin-top: 10px;
  }

  .price-section {
    margin-top: 15px;
  }

}

@media (max-width: 850px) and (min-width: 763px) {

  .add-product-button {
    font-size: large !important;
  }

  .add-image-tag {
    width: 80px;
    height: 80px;
  }
}