
.top-nav {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 10px 20px;
    background-color:black;
    color: white;
    position: fixed;
    width: calc(100% - 40px);
    opacity: 1;
    z-index: 9999;
  }
  
  .search-bar {
    padding: 8px;
    border: none;
    border-radius: 4px;
    width: 30%;
    box-sizing: border-box;
    border: 1px solid #3A3A3A;
    border-radius: 8px;
    font-size: 16px;
    background-color:#3A3A3A ;
    background-image: url("../../assets/Walnut/Vector.svg");
    background-position: 11px 8px; 
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
    margin-left: 2px;
    height: 35px;
    color: #ccc;
    background-size: 17px 17px;


  }
  .search-bar::placeholder {
    color:white;
}


  
  .user-info {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 40px;
    cursor: pointer;
  }
  
  .dropdown-btn {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .dropdown-menu {
    display: block !important;
    position: relative;
    right: 0;
    top: 35px;
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    z-index: 1000; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
    min-width: 80px !important;
    width: 150px;
    text-align: center !important;
    height: 52px;

.logout {
      /* margin-bottom: 10px; */
      padding-top: 5px;
      padding-bottom: 5px;
      cursor: pointer;


      /* &:hover{
        background-color:#d3d3d3;
        color: white;
      } */
    }
    .delete{
      /* margin-bottom: 10px; */
      padding-top: 5px;
      padding-bottom: 5px;
      cursor: pointer;


      /* &:hover{
        background-color:#d3d3d3;
        color: white;
      } */
    }
  }

  
  
  .dropdown-menu button {
    background: none;
    border: none;
    color: black;
    cursor: pointer;
    margin-left: 7px;
  }

  .vl {
    border-left: 3px solid gray;
    height: 30px;
    margin-right: 25px;
  }





