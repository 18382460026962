.members-management {
    padding: 45px;
    font-family: Arial, sans-serif;
  }

  .filters select {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 14px;
    outline: none;
    transition: box-shadow 0.3s;
  }
  
  .members-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .members-table th, .members-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .members-table tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
  
  .member-details {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 30px;
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }
  
  .member-info {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .member-info img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .member-meta {
    display: flex;
    gap: 200px;
    /* flex-wrap: wrap; */
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content:center;
  }
  
  .members-management {
    padding: 30px;
    font-family: 'Poppins', sans-serif;
    background-color: #f4f6f8;
    color: #333;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding-left: 20px;
    margin-top: 15px;
    
  }
  
  .filters {
    display: flex;
    gap: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .search-textj{
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 14px;
    outline: none;
    transition: box-shadow 0.3s;
    width:25%;
    background-image: url("../../assets/Walnut/Vector.svg");
    background-position: 6px 8px; 
    background-repeat: no-repeat;
    padding: 10px 80px 10px 30px;
    height: 40px;
    background-size: 17px 17px;
  }

.search-textj::placeholder {
  font-weight: bold; 
}

.input-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
  color: fieldtext !important;
}

  .date-text{

    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 14px;
    outline: none;
    transition: box-shadow 0.3s;
    width:175px;
  }

  .members-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
  }

.member-p-tag{
  margin-bottom: 0px !important;
}

  .members-table th,
  .members-table td {
    padding: 16px 20px;
    text-align: left;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    color: #555;
  }
  
  .members-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #333;
  }
  
  .members-table tr:hover {
    background-color: #f1f5ff;
    cursor: pointer;
  }
  
  .members-table td:last-child {
    text-align: center;
  }
  
  .members-table td button {
    background-color: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .members-table td button:hover {
    color: #0056b3;
  }
  
  .member-info div h3 {
    font-size: 18px;
    margin: 0 0 8px 0;
    color: #333;
  }
  
  .member-info div p {
    margin: 0;
    color: #666;
    font-size: 14px;
  }
  
  

  .member-meta div {
    padding: 15px 20px;
    background-color: #EEF0F1;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    font-size: 14px;
    color: #555;
    text-align: center;
    width:250px;
    display: flex;
    text-transform: capitalize;

  }
  
  .member-meta div strong {
    display: block;
    font-size: 12px;
    margin-bottom: 6px;
    color: #333;
  }

  .profile{
    display: flex;
    gap: 25px;

  }

  .selectmemSection{
    display: flex;
    gap: 125px;
    margin-top: 10px;
  }
  
  .upgarde-section{
    display: flex;
    align-items: center;
    gap: 25px;
    padding-right: 40px;
  }

.filter-three{
    display: flex;
    gap: 152px;
    margin-left: 225px;
}
.dash-ul{
    list-style-type: none;
}

hr {
  border: none;
  border-top: 2px solid #F1EFEF; 
}

body {
  margin: 0;
  padding: 0;

}

.container {
  display: flex;
  height: 100%;
}

.left-sidenav {
  width: 250px; 
  background-color: #f6f8fa;
  position: fixed; 
  height: 100%; 
}

.top-navbar {
  position: fixed; 
  top: 0;
  left: 250px; 
  right: 0; 
  height: 60px; 
  background-color: #fff; 
  z-index: 1;
  border-bottom: 1px solid #ccc; 
}

.content {
  margin-top: 60px; 
  margin-left: 250px; 
  padding: 20px;
  height: calc(100vh - 60px); 
  overflow-y: auto; 
}

.padding-container{
  width: calc(100% - 100px);
    position: absolute;
    left: 80px;
    margin-top: 75px;
}

.capitalize{
  text-transform: capitalize;
}

.error-data{
text-align: center;
padding: 20px;

}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: bold;
}

.member-table {
  padding-left: 20px;
}

.filters{
  margin-bottom: 16px;
  margin-top: 20px;
}

.member-details{
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.member-closeicon{
position: absolute;
top: 10px;
right: 10px;
cursor: pointer;
}

.profile{
display: flex;
align-items: center;
}

.memberemail-image {
  display: flex;
  align-items: center;
}

.email-image{
margin-right: 10px;
border-radius: 50%;
}

.mebermeta-divs{
background-color:#EEF0F1 ;
padding: 10px;
}

.header-name{
  font-size: 1.5rem !important;
}

/* modal */

.note{
display: grid;
gap: 10px;

}
.note-lable{
font-size: 16px;
font-weight: 500;
}

.note-textarea{
width: 100%;
height: 120px;
padding: 20px;
font-size: 14px;
border-radius: 4px;
border: 1px solid #ccc;
resize: vertical;
text-transform: capitalize;
}

.frame-image{
cursor: pointer;
margin-right: 10px;
}

.member-delete{
  position: absolute;
top: 10px;
right: 60px;
cursor: pointer;
}

.member-iconsection{
  display: flex;
  align-items: center;
  gap: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Prevent body overflow */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.avatar-1 {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.inline-container {
  display: flex;
  margin-top: 15px;
 justify-content: center;
 font-size: x-large;
 font-weight: 700;
}

.inline-container p {
  margin-right: 10px;
}

.input-container,
.input-container-2 {
  display: flex;
  gap: 16px;
  flex-wrap: wrap; 
}

.member-div,
.input-container-2 > div {
  flex: 1; 
  min-width: 200px; 
}

.input-container-1{
display: flex;
gap: 20px;
}


.member-menurole{
color: red;
font-size: 12px;
margin-top: 4px;
}

.error-message {
  min-height: 23px; 
  visibility: hidden; 
  color: red;
  font-size: 12px; 
}

.error-message.visible {
  visibility: visible; 
}

.member-star{
  color: red;
}

.member-div{
  flex: 1;
}

.member-lable{
font-size: 19px;
font-weight: 400;
display: block;
margin-bottom: 0px;
margin-top: 10px;
}

.member-lable1{
  font-size: 19px;
font-weight: 400;
display: block;
margin-bottom: 0px;
margin-top: 10px;
}

.createForm {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
}
.input-container .MuiTextField-root {
  flex: 1; /* Makes each text field take equal width */
}

.input-container .MuiTextField-root:first-child {
  margin-right: 8px; /* Adds space between the two fields */
}

.member-cancel {
  width: 210px;
  background-color: white;
  border: 1px solid black;
  height: 45px;
  color: black;
  border-radius: 5px;

}
.member-button {
  width: 210px;
  background-color: black;
  border: 1px solid white;
  height: 45px;
  color: white;
  border-radius: 5px;

}

.dialogButton{
  background-color: #F6F8FA;
  padding: 16px !important;
  gap: 10px;
  justify-content: center !important;
}

.formDesign{
  margin-top: 8px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.cancelBtn{
  background-color: #fff !important;
  color: black !important;
  padding: 6px 8px !important;
  font-size: 16px;
  border: 1px solid black !important;
  text-align: center;
  width: 140px;
}

.deleteBtn{
    background-color: #000 !important;
    color: white !important;
    padding: 6px 8px !important;
    font-size: 16px;
    border: 1px solid black !important;
    text-align: center;
    width: 140px;
}

.titleCss{
  text-align: center;
  font-size: 25px;
  font-weight: 700;
}

.titleDialog{
  font-size: 2rem !important;
  font-weight: 700 !important;
  text-align: center;
  padding-top: 24px !important;
}

.actionDialog{
  justify-content: center !important;
  padding-bottom: 24px !important;
  gap: 20px;
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .selectmemSection {
    gap: 13px;
}

.member-meta {
  gap:14px ;
}

}

@media  (max-width: 1440px)   {
  .member-meta {
    gap: 90px;
  
}
.member-meta div{
  width:200px;
  height: 65px;
}

}

@media (min-width: 768px) and (max-width: 1023px) {
  .selectmemSection {
    gap: 5px;
}

.member-meta {
  gap:10px ;
}
.profileimg{
  width: 65px;
  height: 65px;
}
.capitalize {
font-size: large;
}
.member-meta div {
  width: 165px;
  height: 50px;
  font-size: 10px;
}
.profile-image {
  width: 60px;
  height: 60px;
  font-size: 22px;
}
}



