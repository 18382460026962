.sidenav {
    height: 100%;
    width: 60px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #111;
    /* padding-top: 20px; */
    z-index: 999;
  }
  
  .sidenav a {
    padding: 8px 16px;
    text-decoration: none;
    font-size: 18px;
    color: white;
    display: block;
  }
  
  .sidenav a:hover {
    background-color: #575757;
    color: white;
  }

  .sidenav-logo{
    height: auto;
  }
  
  .icon-btn {
    background:none;
    border: none;
    padding:15px;
  }

  .icon-buttons{
    text-align: center;
  }

  .button-sections{
    padding-bottom: 20px;
  }


  .icon-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.3s ease, fill 0.3s ease;
    outline: none; 
  }
  
  .icon-btn:focus,
  .icon-btn:hover,
  .icon-btn.active {
    background-color: #3A3A3A; 
  }
  
  .icon-btn svg path {
    transition: fill 0.3s ease;
  }
  
  .icon-btn:focus svg path,
  .icon-btn:hover svg path,
  .icon-btn.active svg path {
    fill: white; 
  }
  