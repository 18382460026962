/* Prevent body overflow */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.avatar-1 {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.inline-container {
  display: flex;
  margin-top: 15px;
 justify-content: center;
 font-size: x-large;
 font-weight: 700;
}

.inline-container p {
  margin-right: 10px;
}

.input-container,
.input-container-2 {
  display: flex;
  gap: 16px;
  flex-wrap: wrap; 
}

.create-div,
.input-container-2 > div {
  flex: 1; 
  min-width: 200px; 
}

.input-container-1{
display: flex;
gap: 20px;
}


.create-menurole{
color: red;
font-size: 12px;
margin-top: 4px;
}

.error-message {
  min-height: 23px; 
  visibility: hidden; 
  color: red;
  font-size: 12px; 
}

.error-message.visible {
  visibility: visible; 
}

.create-star{
  color: red;
}

.create-div{
  flex: 1;
}

.create-lable{
font-size: 19px;
font-weight: 400;
display: block;
margin-bottom: 0px;
}

.create-lable1{
  font-size: 19px;
font-weight: 400;
display: block;
margin-bottom: 0px;
margin-top: 10px;
}

.createForm {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
}
.input-container .MuiTextField-root {
  flex: 1; /* Makes each text field take equal width */
}

.input-container .MuiTextField-root:first-child {
  margin-right: 8px; /* Adds space between the two fields */
}

.create-cancel {
  width: 210px;
  background-color: white;
  border: 1px solid black;
  height: 45px;
  color: black;
  border-radius: 5px;

}
.create-button {
  width: 210px;
  background-color: black;
  border: 1px solid white;
  height: 45px;
  color: white;
  border-radius: 5px;

}

.input-container-1 {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.css-er619e-MuiFormHelperText-root {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0px !important;
  margin-right: 14px;
}

.css-er619e-MuiFormHelperText-root.Mui-error{
  margin-left: 2px;
}